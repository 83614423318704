import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from '../../services/appointment.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { merge, startWith, switchMap, catchError, map } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-opd',
  templateUrl: './opd.component.html',
  styleUrls: ['./opd.component.scss']
})
export class OpdComponent {
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  formAction = "add";
	closeResult = '';
  isSubmitted: boolean = false;
  displayedColumns: string[] = ["pId","opd","name","doctor_name","date","time","check_in_type","type","source","status","action"];
  exportColumns:any = ["pId","OPD No","Name","Doctor","Date","Time","Check In","Visit","Source","Payment","Status"];
  exportArrayData:any = []
  constructor(private router:Router, private toast:ToastrService, private api:AppointmentService,private modalService: NgbModal, private localStorage:LocalStorageService){
  }
  ngAfterViewInit() {
    this.loadData();
  }
  dateFilter(event:any)
  {
   this.dateQuery.setValue(event)
  }
  
    // load Initial table data from based on source type
    loadData()
    {
      try{
     this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
     merge(this.dateQuery.valueChanges,this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
     switchMap(()=>{
      let param = {
        where:{hospital_id:this.localStorage.getHospitalId(),center_id:this.localStorage.getCenterId()},
        filter:this.searchQuery.value,
        page:(this.paginator.pageIndex *  this.paginator.pageSize),
        status:'check_in',
        limit:this.paginator.pageSize,
        order:[{col:'app_id',dir:"desc"},{col:'appointment_date',dir:"desc"},{col:'appointment_time',dir:"desc"}],
      }
      if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}
      if(this.localStorage.checkFullAccessForTableList(this.localStorage.getRole())){
        param = param
      }else{
        param = {...param,...{user_id:this.localStorage.getUserId()}}
      }
      return  this.api.getAllAppointmentWithCount(param)
      .pipe(catchError(() => observableOf(null)));
     }),map((response:any)=>{
      if (response === null) {
        return [];
      }
   
      this.totalRows = response.count;
      return response
     })
     ).subscribe({
      next:(data) =>{
     // console.log(data)
      this.exportArrayData = data.rows;
      this.dataSource = new MatTableDataSource<any>(data.rows);;
      },
      error:(e)=>{
       console.error(e)
      },
      complete:()=>{
       
      }
     })
    }
    catch(e){
     console.error(e)
    }
    }
  searchFilter(query:any){

   // alert(query)
    this.searchQuery.setValue(query);
  }
  open(content:any){
  
    this.modalService.open(content, { size:'lg', scrollable: false}).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);

  }
   getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
  gotoPatient(item:any)
  {
    this.router.navigate(['main/out-patient/record/'+item.id+'/'+item.appointment.app_id])
  }
  
  sourceImage = [{
    name:"webapp",
    img:"create.png"
  },
  {
    name:"tina",
    img:"tina-social.png"
  },
  {
    name:"whatsapp",
    img:"logos_whatsapp-icon.png"
  },
  {
    name:"twitter",
    img:"logos_twitter.png"
  },
  {
    name:"facebook",
    img:"logos_facebook.png"
  },
  {
    name:"instagram",
    img:"instagram-icon.png"
  },
  {
    name:"youtube",
    img:"logos_youtube-icon.png"
  },
  {
    name:"messenger",
    img:"logos_messenger.png"
  }]
  getsourceImage(source:any)
  {
    let img:any ="create.png";
  
    let tempImage:any =  this.sourceImage.find((item:any) => {
     return item.name === source;
    })

    if(tempImage)
    {
       img = tempImage.img
    }else
    {
      img = "create.png"
    }
    return img;

  }
  export(header:any)
  {
    //console.log(header)
    let excelRowData:any = [];
    this.exportArrayData.forEach((element:any) => {
     let status = "";
     if( element.appointment.status=="booked")
     {
       status = element.appointment.status.replaceAll('booked','Booked')
     }
     else if( element.appointment.status=="check_in" )
     {
       status = element.appointment.status.replaceAll('check_in','Checked In')
     }
     else if( element.appointment.status=="rescheduled" )
     {
       status = element.appointment.status.replaceAll('rescheduled','Rescheduled')
     }
     else if( element.appointment.status=="cancelled")
     {
       status = element.appointment.status.replaceAll('cancelled','Cancelled')
     }
     else if( element.appointment.status=="no_show")
     {
       status = element.appointment.status.replaceAll('no_show','No Show')
     }
     
     excelRowData.push({0:element.pId,1:element.appointment.opd_number,2:element.fname+" "+element.lname,3:element.appointment.user.fname+" "+element.appointment.user.lname,4:element.appointment.appointment_date,5:element.appointment.appointment_time,6:element.appointment.check_in_type,7:element.appointment.appointment_type,8:element.appointment.appointment_source,9:element.appointment.details,10:status})
   });

    let excelData = [];
    let excelHeader = [];
      for (let i = 0; i < excelRowData.length; i++) {
        let element = excelRowData[i];
        let obj:any = {}
        for (let index = 0; index < header.length; index++) {
          let key = header[index]['key'];
          obj[key] = element[key]
        }
        excelData.push(obj)
      }
      for (let index = 0; index < header.length; index++) {
        let value = header[index]['value'];
        excelHeader.push(value)
      }
    //console.log(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
    XLSX.utils.sheet_add_json(ws,excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();

  XLSX.writeFile(wb, "Out_Patient_"+day+"_"+month+"_"+year+"_"+date.getTime()+".csv");
  }

}
function observableOf(arg0: null): any {
 // throw new Error('Function not implemented.');
}

