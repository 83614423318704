import { Component, Input } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-cataract-surgery',
  templateUrl: './cataract-surgery.component.html',
  styleUrls: ['./cataract-surgery.component.scss']
})
export class CataractSurgeryComponent {
@Input() dischargeDetailObject:any = ''
date_of_consent: string = moment().format("DD-MM-YYYY");
}
