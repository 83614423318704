import * as moment from "moment";
import * as pdfMake from 'pdfmake/build/pdfmake';


const Receipt = (data:any,action:any = 'print') => {

    console.log(data)
    let recTitle = (data.payment_method==='Bill') ? 'Bill No' : 'Receipt No'
    let total_tax  = (data.total_tax) ? data.total_tax : 0;
    let bill_number  = data.receipt_no ;
    let bill_item  = data.billings_items || [];
    // [{text: 'Consultation Charges', alignment: 'left',fontSize: 10},'300'],
    var items:any = [];
    var values:any = [];
    var itemscopy:any = [];
    var valuescopy:any = [];
    bill_item.forEach((element:any,index:any) => {
            let i = index + 1;
            total_tax = total_tax +  element.tax;
            items.push({ margin:[0,2,0,2],text: i+" - "+element.item_name, alignment: 'left',fontSize: 10});
            values.push({ margin:[0,2,0,2],text: "₹"+element.item_price, alignment: 'right',fontSize: 10});
            itemscopy.push({ margin:[0,2,0,2],text: i+" - "+element.item_name, alignment: 'left',fontSize: 10});
            valuescopy.push({ margin:[0,2,0,2],text: "₹"+element.item_price, alignment: 'right',fontSize: 10});
     
    });
    let docDefinition:any = {
        //  pageSize: {
        //   width: 595.28,
        //   height: 'auto'
        //  },
     
        content: [ 
          {
            columns: [
              
               
                getLogo()
              ,
              { 
                width: "*",
                text: data.address,fontSize: 10  ,   alignment: 'right',},
             
            
            ]
          },
          {
            
            columns: [
              
             
               
               
                // [ 
                //   getLogo(),
                //   { margin:[0,10,0,0], text: data.hospital_name,fontSize: 12,bold:true,TextColor:"#069" ,   alignment: 'right',},
                //   { text: data.address,fontSize: 10  ,   alignment: 'right',},
                  
                // ],
                
               
            ]
        }
      ,{canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5,dash: {length: 5, space: 2}}]},
    
       {
        margin:[0,10,0,5],
        columns:[
          { text: recTitle+": "+bill_number,fontSize: 10,TextColor:"#069", alignment: 'left', },
          { text: "Date :"+data.billing_date,fontSize: 10,TextColor:"#069", alignment: 'right', },
        ]
       },
         {
          
          table:{
            headerRows: 2,
            heights: ['auto','auto',50 ],
            widths: ["*", 'auto'],
     
            body: [
                        [{
                layout: 'noBorders',table:{
                
                headerRows: 0,
                widths: ["auto", 'auto','auto','*'],
                body:[

                  [{text: 'Name',  alignment: 'left',fontSize: 10},":",{text: data.patient_name,  alignment: 'left',fontSize: 10},{text: (data.uhid!="") ? "UHID No : "+data.uhid : "PID NO : "+ data.pId,  alignment: 'right',fontSize: 10}],
                  [{text: 'Gender/Age', alignment: 'left',fontSize: 10},":",{text: data.gender+`/`+data.age+'(Yrs)',  alignment: 'left',fontSize: 10},{text: "OPD NO : "+ data.opd_no,  alignment: 'right',fontSize: 10}],
                  [{text: 'Address', alignment: 'left',fontSize: 10},":",{text:  data.patient_address,  alignment: 'left',fontSize: 10},""],
                  [{text: 'Doctor', alignment: 'left',fontSize: 10},":",{text:  "DR."+data.consultant,  alignment: 'left',fontSize: 10},""]
                ]
    
              },colSpan: 2, rowSpan: 1},""],
    
              

              [{text: 'Particulars', alignment: 'left',fontSize: 10},
              {text: 'Amount', alignment: 'left',fontSize: 10}],
            
              [items,values],
              [{text: 'Subtotal', alignment: 'right',fontSize: 10},{text: "₹"+data.subtotal, alignment: 'right',fontSize: 10}],
              [{text: 'Discount', alignment: 'right',fontSize: 10},{text: "- ₹"+data.discount?.amount, alignment: 'right',fontSize: 10}],  
              [{text: 'Tax', alignment: 'right',fontSize: 10},{text: "₹"+Math.round(total_tax) || 0, alignment: 'right',fontSize: 10}],  
            [{text: 'Total', alignment: 'right',fontSize: 10},{text: "₹"+data.amount || 0, alignment: 'right',fontSize: 10}],
            [{text: 'Payment Method', alignment: 'right',fontSize: 10},{text: data.payment_method , alignment: 'right',fontSize: 10}]
            
            ]
            
          }
         },
         {
          margin:[0,10,0,10],
          text: "(Rupees :"+" "+ inWords(data.amount)+".)",fontSize: 10,TextColor:"#069", alignment: 'left',
         },
         {
          margin:[0,10,0,10],
          text: "Authorized Signature",fontSize: 10,TextColor:"#069", alignment: 'right',
         },
         ,{canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5,dash: {length: 5, space: 2}}]},
        

      //    // Copy Receipt 
      //    ,{canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5,dash: {length: 5, space: 2}}]},
      //    {
      //       margin:[0,20,0,5],
      //       columns: [
              
               
      //           [
                    
      //             { text: data.hospital_name,fontSize: 12,bold:true,TextColor:"#069" ,   alignment: 'center',},
      //             { text: data.address,fontSize: 10  ,   alignment: 'center',},
                  
      //           ],
               
    
                
               
      //       ]
      //   }
      // ,{canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5,dash: {length: 5, space: 2}}]},
    
      //  {
      //   margin:[0,10,0,5],
      //   columns:[
      //     { text: recTitle+": "+bill_number,fontSize: 10,TextColor:"#069", alignment: 'left', },
      //     { text: "Date :"+data.billing_date,fontSize: 10,TextColor:"#069", alignment: 'right', },
      //   ]
      //  },
     
      //    {
          
      //     table:{
      //       headerRows: 2,
      //       heights: ['auto','auto',50 ],
      //       widths: ["*", 'auto'],
     
      //       body: [
      //                   [{
      //           layout: 'noBorders',table:{
                
      //           headerRows: 0,
      //           widths: ["auto", 'auto','auto'],
      //           body:[
      //             [{text: 'Name',  alignment: 'left',fontSize: 10},":",{text: data.patient_name,  alignment: 'left',fontSize: 10},],
      //             [{text: 'OPD No.', alignment: 'left',fontSize: 10},":",{text: data.opd_no,  alignment: 'left',fontSize: 10},],
      //             [{text: 'Gender/Age', alignment: 'left',fontSize: 10},":",{text: data.gender+`/`+data.age+'(Yrs)',  alignment: 'left',fontSize: 10},],
      //             [{text: 'Consultant', alignment: 'left',fontSize: 10},":",{text:  data.consultant,  alignment: 'left',fontSize: 10},]
      //           ]
    
      //         },colSpan: 2, rowSpan: 1},""],
    
      //         [{text: 'Particulars', alignment: 'left',fontSize: 10},
      //         {text: 'Amount', alignment: 'left',fontSize: 10}],
      //         [itemscopy,valuescopy],
      //         [{text: 'Subtotal', alignment: 'right',fontSize: 10},{text: "₹"+data.subtotal, alignment: 'right',fontSize: 10}],
      //         [{text: 'Discount', alignment: 'right',fontSize: 10},{text: "- ₹"+data.discount.amount, alignment: 'right',fontSize: 10}],  
      //         [{text: 'Tax', alignment: 'right',fontSize: 10},{text: "₹"+total_tax, alignment: 'right',fontSize: 10}],  
      //       [{text: 'Total', alignment: 'right',fontSize: 10},{text: "₹"+data.amount, alignment: 'right',fontSize: 10}]
      //       ]
            
      //     }
      //    },
      //    {
      //     margin:[0,10,0,10],
      //     text: "(Rupee :"+" "+ inWords(data.amount)+".)",fontSize: 10,TextColor:"#069", alignment: 'left',
      //    },
      //    ,{canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5,dash: {length: 5, space: 2}}]},
      //    {
      //     margin:[0,5,0,5],
      //     text: "For , Patient only",fontSize: 10,TextColor:"#069", alignment: 'right',
      //    }
        //  ,{canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5,dash: {length: 5, space: 2}}]},
         ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 5,0, 5]          
          },
          ul: {
            fontSize: 10,
          },
          tableHeader: {
            bold: false,
            fontSize: 11,
            color: 'black',
        
          }
        }
      };
   
    

      if(action==='download'){
        pdfMake.createPdf(docDefinition).download();
      }else if(action === 'print'){
        pdfMake.createPdf(docDefinition).print();      
      }else{
        pdfMake.createPdf(docDefinition).open();      
      }

}

var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

function inWords (num:any) {
    if ((num = num.toString()).length > 9) return 'overflow';
    let n:any = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]])  : '';
    str +="Only";
    return str.toUpperCase();
}

function getLogo()
{
 let object;

 if(localStorage.getItem('getBase64logo'))
 {
  object = {
    width: 250,
    image:localStorage.getItem('getBase64logo') || "",
    fit: [150, 150],
    alignment: 'left',
  }
 }
 return object;


}

export default Receipt;