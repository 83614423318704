import { Component, ViewChild ,TemplateRef} from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { MasterService } from 'src/app/private/services/master.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-price-settings',
  templateUrl: './price-settings.component.html',
  styleUrls: ['./price-settings.component.scss']
})
export class PriceSettingsComponent {
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  priceSettings:PriceSettings = new PriceSettings();
  @ViewChild("priceSettingsForm")
  priceSettingsForm!: NgForm;
  isSubmitted:boolean = false
  formAction = 'add'
  categoryList:any = [];
  displayedColumns: string[] = ["category","item","item_code",'price',"date","action"];
  constructor(private localStorage: LocalStorageService, private toast:ToastrService,private offcanvasService: NgbOffcanvas,private api:MasterService){}
  searchFilter(query:any){
    this.searchQuery.setValue(query);
  }

  openSeeting(content: TemplateRef<any>) {

		this.offcanvasService.open(content, { position: 'end' });
	}
  edit(content:any,object:any){
    this.formAction = "update";
    this. getPriceCategories();
    this.offcanvasService.open(content, { position: 'end' });
    this.getPriceDetails(object.id)
   
  }
  getPriceDetails(id:any)
  {
    let param = {
      id:id,
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
    }
     this.api.getPriceMaster(param).subscribe({next:(res)=>{this.priceSettings = res || {}},error:(e)=>{}})
  }
  open(content:any){
    this.openSeeting(content)
    this.formAction = 'add'
    this.priceSettings =  new PriceSettings();
    this.priceSettings.item_code = this.generateUniqueCode();
    this. getPriceCategories();
  }
  getPriceCategories()
  {
    this.api.getAllPriceCategory().subscribe({next:(res)=>{this.categoryList = res},error:(e)=>{}})
  }

  save(isValid:any){

    this.isSubmitted = true;
    if(isValid)
    {
      this.isLoading = true
    //  alert(this.formAction)
      this.priceSettings.hospital_id = this.localStorage.getHospitalId();
      this.priceSettings.center_id = this.localStorage.getCenterId();
      if(this.formAction == 'add')
      {
        //console.log(this.priceSettings);
       this.api.createPriceMaster(this.priceSettings).subscribe({
        next:(res)=>{
         this.loadData();
         this.offcanvasService.dismiss();
         this.toast.success("Item Added Successfully",'Success')
         this.isLoading = false
         this.isSubmitted = false;
         this.formAction = "";
        },
        error:(e)=>{  
          this.toast.error(e.error.msg,'Error')
          this.isLoading = false
          this.isSubmitted = false;
          this.formAction = "";
          
        }
       })
      }
      else if(this.formAction == 'update')
      {
        this.api.UpdatePriceMaster(this.priceSettings).subscribe({
          next:(res)=>{
           this.loadData();
           this.offcanvasService.dismiss();
           this.toast.success("Item Update Successfully",'Success')
           this.isLoading = false
           this.isSubmitted = false;
           this.formAction = "";
          },
          error:(e)=>{  
            this.toast.error(e.error.msg,'Error')
            this.isLoading = false
            this.isSubmitted = false;
            this.formAction = "";
          }
         })
      }
    }
  }
  ngAfterViewInit(){this.loadData();}
  loadData()
  {
    try{
   this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
   merge(this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
   switchMap(()=>{
    let param = {
      where:{hospital_id:this.localStorage.getHospitalId(),center_id:this.localStorage.getCenterId()},
      page:(this.paginator.pageIndex *  this.paginator.pageSize),
      limit:this.paginator.pageSize,
      filter:this.searchQuery.value,
      order:[{col:'createdAt',dir:"desc"}],
    }
    return  this.api.getAllPriceMasterWithCount(param)
    .pipe(catchError(() => observableOf(null)));
   }),map((response:any)=>{
    if (response === null) {
      return [];
    }
 
    this.totalRows = response.count;
    return response
   })
   ).subscribe({
    next:(data) =>{
    this.dataSource = new MatTableDataSource<any>(data.rows);
    },
    error:(e)=>{
     console.error(e)
    },
    complete:()=>{
     
    }
   })
  }
  catch(e){
   console.error(e)
  }
  }
  generateUniqueCode(): string {
    const prefix = 'INV';
    const date = new Date().toISOString().split('T')[0].replace(/-/g, '');
    const uniqueId =  this.totalRows + 1; // or use a sequence number
    return `${prefix}-${date}-${uniqueId}`;
  }
}

export class PriceSettings {
  id!: Number;
  cat_id:String = "";
  item: string = "";
  item_code: string = "";
  price: Number = 0.0;
  hospital_id:Number = 0;
  center_id:Number = 0;
}

function observableOf(arg0: null): any {
  throw new Error('Function not implemented.');
}
