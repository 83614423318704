import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DateFilterComponent } from 'src/app/private/shared/ui/date-filter/date-filter.component';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-billing-statement-table',
  templateUrl: './billing-statement-table.component.html',
  styleUrls: ['./billing-statement-table.component.scss']
})
export class BillingStatementTableComponent {
  DateFilterComponent = DateFilterComponent;
  @Input() BillingDataList:any;
  @Input() totalCollection:any;
  _day:any = "Total";
  FilterdateStart:Date =  new Date();
  Filterdateend:Date =  new Date();
  @Output("DateFilter") DateFilter: EventEmitter<any> = new EventEmitter();
  @Output("PaymentFilter") PaymentFilter: EventEmitter<any> = new EventEmitter();
  @Output("SearchFilter") SearchFilter: EventEmitter<any> = new EventEmitter();
  fileName= moment().format("YYYY-MM-DD")+'_collection_report.xlsx';  
  actionButton:any = "search"
  toggleExport:boolean = false;
  SearchInput:any;
  constructor(private toast:ToastrService) {
    setTimeout(() => {
      let object = {startDate:moment().format("YYYY-MM-DD"),endDate:moment().format("YYYY-MM-DD")}
     // this.DateFilter.emit(object)
      //this. _day = this.categorizeDateDifference(object.startDate, object.endDate)
    }, 1500);

  }
   categorizeDateDifference(date1:any, date2:any) {
    const mDate1 = moment(date1);
    const mDate2 = moment(date2);
  
    if (mDate1.isSame(mDate2, 'day')) {
      return 'today';
    }
    if (mDate1.isSame(mDate2, 'week')) {
      return 'this week';
    }
    if (mDate1.isSame(mDate2, 'month')) {
      return 'this month';
    }
    if (mDate1.isSame(mDate2, 'year')) {
      return 'this year';
    }
  
    const diffInDays = mDate2.diff(mDate1, 'days') % 7;
    const diffInWeeks = mDate2.diff(mDate1, 'weeks') % 4;
    const diffInMonths = mDate2.diff(mDate1, 'months') % 12;
    const diffInYears = mDate2.diff(mDate1, 'years');
  
    return `difference: ${diffInYears} years, ${diffInMonths} months, ${diffInWeeks} weeks, and ${diffInDays} days`;
  }
  filterByDateRange()
{
  let start = (this.FilterdateStart!=null) ? new Date(this.FilterdateStart.setHours(0,0,0,0)) :this.FilterdateStart;;
  let end =  (this.Filterdateend!=null) ? new Date(this.Filterdateend.setHours(0,0,0,0)) :this.Filterdateend;

  let startd = moment(start).format("YYYY-MM-DD")
  let endb = moment(end).format("YYYY-MM-DD")
  if(startd!= "Invalid date" && endb!= "Invalid date")
  {
    let object = {startDate:startd,endDate:endb}
    this. _day = this.categorizeDateDifference(object.startDate, object.endDate)
    this.DateFilter.emit(object)
  }

}
onPaymentTypeChange(event:any)
{
  if(event.target.value){
    let object = {payment_method:event.target.value}
    this.PaymentFilter.emit(object)
  }
  else {
    this.PaymentFilter.emit("")
  }

}
export(): void 
    {
       /* table id is passed over here */   
  
       var Heading = [
        ["S.No", "OPD No", "Invoice No", "Invoice Date", "Payment Status", "Payment Method", "Paid Amount"],
      ];
      let data:any = [];
      console.log(this.BillingDataList)
      this.BillingDataList.forEach((element:any,i:any) => {
      
        data.push({sr:i+1,opd_number:element.opd_no,invoice_number:element.receipt_no,billing_date:element.billing_date,status:element.status,payment_method:element.payment_method,amount:element.amount})
      });
  
  
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');


       /* save to file */
       XLSX.writeFile(wb, this.fileName);
			
    
}
resetDatePicker()
{
  this.DateFilter.emit("")
}

getFilterValue ()
{
 
  let query = this.SearchInput
  let object:any = {}

  
  if(query=="" || query == undefined)
  {
    this.toast.error("Enter Name,Email,Mobile,PId,UHID")
  }
  else
  {
    object = {
      'fname':query,
      'lname':query,
      'mobile':query,
      'email':query,
      'pId':query,
      'old_patient_record_number':query,
    }

    this.SearchFilter.emit(object)
    this.actionButton = 'reset'; 
  }

  

}

resetFilter()
{
  this.actionButton = 'search'; 
  this.SearchInput = ""
  this.SearchFilter.emit("")
  this.PaymentFilter.emit("")

}
}
