import { Component } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas'
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IpdchartService } from 'src/app/private/services/ipdchart.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import moment from 'moment';
@Component({
  selector: 'app-consent-form',
  templateUrl: './consent-form.component.html',
  styleUrls: ['./consent-form.component.scss']
})
export class ConsentFormComponent {
  current_patient_id:any;
  ipd_app_id:any;
  btnSaveLoading:boolean = false;
  dischargeDetailObject:any;
  

  consent_form:any = "";
  constructor(private toast:ToastrService,public route:ActivatedRoute,private ipdChartData:IpdchartService, private templateService:TemplateService,private localStorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.ipd_app_id = params['ipd_app_id']
      })
  }
  selectConsentForm(event:any){
    if(event.target.value) {
      this.consent_form = event.target.value
    }

  }
  ngAfterViewInit()
  {
    this.getDischargeDetail();
  }
  getDischargeDetail()
  {

    let param = {
   
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
      patient_id:this.current_patient_id,
      id:this.ipd_app_id,
      }
     this.ipdChartData.getDischargeDetails(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.dischargeDetailObject = res;
        }
      },
      error:(e)=>{

      }
     })
    
  }
  async getImageFromCanvas(page:any)
  {
    const canvas = await html2canvas(page);
    console.log(canvas);
    return   canvas.toDataURL("image/png", 1.0);;
  }
  getDimensionPage(doc:any,image:any)
  {
    let bufferX = 5;
    const bufferY = 5;
    const imgProps = (<any>doc).getImageProperties(image);
    const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferY;
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    const type = "PNG";
    const compression = 'FAST';
    return {image,type, bufferX, bufferY, pdfWidth, pdfHeight, undefined, compression}
  }
  async printPage()
  {
  //  this.btnSaveLoading = true;
    const data = document.getElementById('discharge_summery'); // The ID of the HTML element to convert to PDF
  if (data) {
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png',1.0)  
      var margin = 8;
      var imgWidth = 210 - 2*margin; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
    
      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.setFont("helvetica");
    
      doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);
    
      heightLeft -= pageHeight;
    
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      var pdfUrl = URL.createObjectURL(doc.output('blob'));
      window.open(pdfUrl, "_blank")?.print();
      // Save the generated PDF
      //pdf.save('GeneratedDocument.pdf');
    });
  }


 
  }

  

}
