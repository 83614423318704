import { Component, Input } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-intravitreal-injection-form',
  templateUrl: './intravitreal-injection-form.component.html',
  styleUrls: ['./intravitreal-injection-form.component.scss']
})
export class IntravitrealInjectionFormComponent {
@Input() dischargeDetailObject:any = ''
date_of_consent: string = moment().format("DD-MM-YYYY");
doctor:any = ""
constructor(){

}
}
