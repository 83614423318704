
// const BASE_URL_PART_A = "https://91jgb78v3l.execute-api.us-east-1.amazonaws.com/prod/"
// const BASE_URL_PART_B = "https://sbk38t5owc.execute-api.us-east-1.amazonaws.com/prod/"
// const BASE_URL_PART_C = "https://8xm5kocfwa.execute-api.us-east-1.amazonaws.com/prod/"
// const BASE_URL_PART_D = "https://4t28ooshtf.execute-api.us-east-1.amazonaws.com/prod/"

// const BASE_URL_PART_A = "http://localhost:4000/dev/"
// const BASE_URL_PART_B = "http://localhost:5000/dev/"
// const BASE_URL_PART_C = "http://localhost:5050/dev/"
// const BASE_URL_PART_D = "http://localhost:5008/dev/"


const BASE_URL_PART_A = "https://jxhgjdi24g.execute-api.us-east-1.amazonaws.com/dev/"
const BASE_URL_PART_B = "https://pzafw14o5j.execute-api.us-east-1.amazonaws.com/dev/"
const BASE_URL_PART_C = "https://fkcx8y9pl3.execute-api.us-east-1.amazonaws.com/dev/"
const BASE_URL_PART_D = "https://ea8079z0hj.execute-api.us-east-1.amazonaws.com/dev/"



//ImgUpload
const UPLOAD_URL = "https://8x1pz40uv9.execute-api.us-east-1.amazonaws.com/dev/uploadfile/"

export const environment = {
    //Auth 

    loginUser:BASE_URL_PART_A+"loginUser",
    forgotPassword:BASE_URL_PART_A+"forgotPassword",
    resetPassword:BASE_URL_PART_A+"resetPassword",
    verifyOtp:BASE_URL_PART_A+"verifyOtp",
    getAnalytics:"https://zt4vunflkg.execute-api.us-east-1.amazonaws.com/prod/getAnalytics",

    //Leads
    getAllLeads:BASE_URL_PART_A+"getAllLeads",
    createLead:BASE_URL_PART_A+"createLead",
    getLead:BASE_URL_PART_A+"getLead",
    updateLead:BASE_URL_PART_A+"updateLead",
    createleadsfollowup:BASE_URL_PART_A+"createleadsfollowup",

    // Ticket Managment
    getTinaTicketHistory: BASE_URL_PART_C + "getAllTicketHistory",
    getUsers: BASE_URL_PART_C + "getAlluser",
    getAllTinaTicket: BASE_URL_PART_C + "getAllTickets",
    createTicket: BASE_URL_PART_C + "createTicket",
    updateTicket: BASE_URL_PART_C + "updateTicket",
    updateBulkTicket: BASE_URL_PART_C + "updateMultipleTickets",
    updateTicketHistory: BASE_URL_PART_C + "createTicketHistory",
    createBulkTicketHistory: BASE_URL_PART_C + "createBulkTicketHis",
    getAllZendeskTicket: "https://pxdoxmhdzj.execute-api.us-east-1.amazonaws.com/production/zendeskListTickets",



    //Manage Users
    getAllUsers:BASE_URL_PART_A+"getAllUsers",
    createUser:BASE_URL_PART_A+"createUser",
    getUser:BASE_URL_PART_A+"getUser",
    userModuleDropdownListing:BASE_URL_PART_A+"userModuleDropdownListing",
    getUserByDepartment:BASE_URL_PART_A+"getUserByDepartment",
    getUserByDepartmentSchedule:BASE_URL_PART_B+"getUserByDepartmentSchedule",
    updateUser:BASE_URL_PART_A+"updateUser",
    getAllCenter:BASE_URL_PART_A+"getAllCenter",
    getAllDepartment:BASE_URL_PART_A+"getAllDepartment",
    getAllRoles:BASE_URL_PART_A+"getAllRoles",
  
    //Scheduled
    createSchedule:BASE_URL_PART_A+"createSchedule",
    getSchedule:BASE_URL_PART_A+"getSchedule",
    getAllSchedule:BASE_URL_PART_A+"getAllSchedule",
    bulkSchedule:BASE_URL_PART_A+"bulkSchedule",
    deleteSchedule:BASE_URL_PART_A+"deleteSchedule",
    updateSchedule:BASE_URL_PART_A+"updateSchedule",

    // pricing dashboard
    getAddOnServices:"https://ea8079z0hj.execute-api.us-east-1.amazonaws.com/dev/getAddOnServices",

    //Price Masters
    getAllPriceMasterWithCount:BASE_URL_PART_A+"getAllPriceMasterWithCount",
    getAllPriceMaster:BASE_URL_PART_A+"getAllPriceMaster",
    createPriceMaster:BASE_URL_PART_A+"createPriceMaster",
    getPriceMaster:BASE_URL_PART_A+"getPriceMaster",
    updatePriceMaster:BASE_URL_PART_A+"updatePriceMaster",
    deletePriceMaster:BASE_URL_PART_A+"deletePriceMaster",

    //Price Category 
    getAllPriceCategory:BASE_URL_PART_A+"getAllPriceCategory",

    //Appointment
    getAllAppointmentWithCount:BASE_URL_PART_A+"getAllAppointmentWithCount",
    createAppointment:BASE_URL_PART_A+"createAppointment",
    getPatient:BASE_URL_PART_A+"getPatient",
    getAllAppointment:BASE_URL_PART_A+"getAllAppointment",
    getAllAppointmentByPatientId:BASE_URL_PART_A+"getAllAppointmentByPatientId",
    getAvailableDays:BASE_URL_PART_A+"getAvailableDays",
    getSlots:BASE_URL_PART_A+"getSlots",
    updateAppointment:BASE_URL_PART_A+"updateAppointment",
    refundAppointmentFees:BASE_URL_PART_A+"refundAppointmentFees",
    checkInOpdPatientData:BASE_URL_PART_B+"checkInOpdPatientData",

    //Patient Data
    getPatientBySearch:BASE_URL_PART_A+"getPatientBySearch",
    upload:UPLOAD_URL,
    updatePatient:BASE_URL_PART_A+"updatePatient",
    
    //Billings
    createBilling:BASE_URL_PART_A+"createBilling",
    getBillingListByPatient:BASE_URL_PART_A+"getBillingListByPatient",
    getReceiptData:BASE_URL_PART_A+"getReceiptData",
    generateReceiptNo:BASE_URL_PART_A+"generateReceiptNo",
    updateBilling:BASE_URL_PART_A+"updateBilling",
    getBillingData:BASE_URL_PART_A+"getBillingData",
    getCreditBillingData:BASE_URL_PART_B+"getCreditBillingData",
     
    //Email config
    createEmailConfig:BASE_URL_PART_A+"createEmailConfig",
    getEmailConfig:BASE_URL_PART_A+"getEmailConfig",
    updateEmailConfig:BASE_URL_PART_A+"updateEmailConfig",
    
    //Templates
    getAllPrimaryCheckupTemplate:BASE_URL_PART_A+"getAllPrimaryCheckupTemplate",
    updatePrimaryCheckupTemplate:BASE_URL_PART_A+"updatePrimaryCheckupTemplate",


    //opdchart
    createOpdChartData:BASE_URL_PART_A+"createOpdChartData",
    getAllOpdChartData:BASE_URL_PART_A+"getAllOpdChartData",
    updateOpdChartData:BASE_URL_PART_A+"updateOpdChartData",
    sharePrescription:BASE_URL_PART_B+"sharePrescription",
    
    
    //Templates
    createMedicationTemplate:BASE_URL_PART_B+"createMedicationTemplate",
    getMedicationTemplate:BASE_URL_PART_B+"getMedicationTemplate",
    createInvestigationTemplate:BASE_URL_PART_B+"createInvestigationTemplate",
    getInvestigationTemplate:BASE_URL_PART_B+"getInvestigationTemplate",
    getAdviceTemplate:BASE_URL_PART_B+"getAdviceTemplate",
    getDiagnosisTemplate:BASE_URL_PART_B+"getDiagnosisTemplate",
    getMedicinesDiagnosisTemplate:BASE_URL_PART_B+"getMedicinesDiagnosisTemplate",
    getAdmissionTemplate:BASE_URL_PART_B+"getAdmissionTemplate",
    

    //IPD
    getAllIpdAppointmentWithCount:BASE_URL_PART_B+"getAllIpdAppointmentWithCount",
    getDoctors:BASE_URL_PART_B+"getDoctors",
    createIPDAppointment:BASE_URL_PART_B+"createIPDAppointment",
    getPatientById:BASE_URL_PART_B+"getPatientById",
    getIPDAppointmentDetails:BASE_URL_PART_B+"getIPDAppointmentDetails",
    checkInIpdPatientData:BASE_URL_PART_B+"checkInIpdPatientData",
    updateIpdAppointment:BASE_URL_PART_B+"updateIpdAppointment",
    createIpdChartData:BASE_URL_PART_B+"createIpdChartData",
    updateIpdChartData:BASE_URL_PART_B+"updateIpdChartData",
    getAllIpdChartData:BASE_URL_PART_B+"getAllIpdChartData",
    getDischargeDetails:BASE_URL_PART_B+"getDischargeDetails",
    
    //IPD Billing
    getBillingModuleData:BASE_URL_PART_B+"getBillingModuleData",
    createIPDBilling:BASE_URL_PART_B+"createIPDBilling",
    getIPDbillsByIPDAppId:BASE_URL_PART_B+"getIPDbillsByIPDAppId",
    updateIPDBilling:BASE_URL_PART_B+"updateIPDBilling",
    getCreditOrganization:BASE_URL_PART_B+"getCreditOrganization",
    generateIPDBillNo:BASE_URL_PART_B+"generateIPDBillNo",
    getIpdBillingPrintData:BASE_URL_PART_B+"getIpdBillingPrintData",
    getIpdBillingDataReport:BASE_URL_PART_B+"getIpdBillingData",
    createCreditNote:BASE_URL_PART_B+"createCreditNote",
    
    
    //Feedbacks
    getAllFeedbacksWithCount:BASE_URL_PART_B+"getAllFeedbacksWithCount",
      
    //Surgery Package
    getProcedurePackageList:BASE_URL_PART_B+"getProcedurePackageList",
    updateProcedurePackage:BASE_URL_PART_B+"updateProcedurePackage",
    createProcedurePackage:BASE_URL_PART_B+"createProcedurePackage",
    getProcedurePackages:BASE_URL_PART_B+"getProcedurePackages",

    //Inventory 
    getAllMedicineInventoryWithCount:BASE_URL_PART_B+"getAllMedicineInventoryWithCount",
    addMedicine:BASE_URL_PART_B+"addMedicine",
    updateMedicine:BASE_URL_PART_B+"updateMedicine",
    deleteMedicine:BASE_URL_PART_B+"deleteMedicine",
    getAllMedicine:BASE_URL_PART_B+"getAllMedicine",
    getInventoryCategory:BASE_URL_PART_B+"getInventoryCategory",
    updateStock:BASE_URL_PART_B+"updateStock",
    

    //Supply 
    getAllSupplyWithCount:BASE_URL_PART_B+"getAllSupplyWithCount",
    addSupply:BASE_URL_PART_B+"addSupply",
    updateSupply:BASE_URL_PART_B+"updateSupply",
    deleteSupply:BASE_URL_PART_B+"deleteSupply",
    getAllSupply:BASE_URL_PART_B+"getAllSupply",

    getTaxes:BASE_URL_PART_B+"getTaxes",
    createTaxes:BASE_URL_PART_B+"createTaxes",
    updateTaxes:BASE_URL_PART_B+"updateTaxes",
    deleteTaxes:BASE_URL_PART_B+"deleteTaxes",

    getPaymentMethods:BASE_URL_PART_B+"getPaymentMethods",
    createPaymentMethods:BASE_URL_PART_B+"createPaymentMethods",
    updatePaymentMethods:BASE_URL_PART_B+"updatePaymentMethods",
    deletePaymentMethods:BASE_URL_PART_B+"deletePaymentMethods",
    
    //Import Database
    importAppointments:BASE_URL_PART_B+"importAppointments",

    // Campaign Modules
    createSubscriberList:BASE_URL_PART_D+"createSubscriberList",
    getAllSubscriberList:BASE_URL_PART_D+"getAllSubscriberList",
    deleteSubscriberList:BASE_URL_PART_D+"deleteSubscriberList",
    getSubsciberList:BASE_URL_PART_D+"getSubscriberlist",
    addCampaignList:BASE_URL_PART_D+"addCampaignList",
    updateCampaignList:BASE_URL_PART_D+"updateCampaignList",
    getAllCampaignList:BASE_URL_PART_D+"getAllCampaignList",
    deleteCampaignList:BASE_URL_PART_D+"deleteCampaignList",
    createCampaignList:BASE_URL_PART_D+"createCampaignList",
    getAllSubsciberList:BASE_URL_PART_D+"getAllSubscriberList",
    deleteSubsciberList:BASE_URL_PART_D+"deleteSubsciberList",
    getAllCampaignWithCount:BASE_URL_PART_D+"getAllCampaignWithCount",
    runCampaignWhatsapp:BASE_URL_PART_D+"runCampaignWhatsapp",
    runCampaignEmail:BASE_URL_PART_D+"runCampaignEmail",
    runCampaignTextMessage:BASE_URL_PART_D+"runCampaignTextMessage",
    //Analytics
    getLeadAnalytics:BASE_URL_PART_D+"getLeadAnalytics",
    getAppointmentAnalytics:BASE_URL_PART_D+"getAppointmentAnalytics",

     //template
     getAllTemplates: "https://ekofx0jipf.execute-api.us-east-1.amazonaws.com/prod/getAllTemplates",
     //msg
     sendWhatsappMsg: "https://ekofx0jipf.execute-api.us-east-1.amazonaws.com/prod/sendWhatsappMsg",
     sendMediaTemplateMsg: "https://ekofx0jipf.execute-api.us-east-1.amazonaws.com/prod/sendMediaTemplateMsg",
 
  };
  
  