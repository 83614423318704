import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BillingService } from 'src/app/private/services/billing.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas'
import { IpdAppointmentService } from 'src/app/private/services/ipdappointment.service';
import { IpdchartService } from 'src/app/private/services/ipdchart.service';

@Component({
  selector: 'app-ipd-billing',
  templateUrl: './ipd-billing.component.html',
  styleUrls: ['./ipd-billing.component.scss']
})
export class IpdBillingComponent {
  @Input() titleOfHead: any = {
    title: 'IPD',
    root: 'IPD Billing'
  };
  @Input() patient:any;

  current_patient_id:any;
  current_app_id:any;
  btnLoadingRecNo:boolean = false;
  btnLoadingDischarge:boolean = false;
  ipdDetailObject:any;
  printData:any
  btnLoading:boolean = false;
  id:any;
  partialPaidInput:boolean = false;
  billingsData:any = [];
  creditOrg:any = []
  partialBillingData:any = [];
  loading:boolean = false;
  loading_cat :boolean = false;
  loading_sub_cat :boolean = false;
  @Output("dischargeFunction") dischargeFunction: EventEmitter<any> = new EventEmitter();
 
  ipdBillObject:any = {
    billing_date:moment().format("YYYY-MM-DD"),
    date_of_discharge:moment().format("YYYY-MM-DD"),
    time_of_discharge:moment().format("HH:mm"),
    total_admit_days:0,
    billing_items:[],
    billing_details:{
      subtotal:0,
      discount:{
        amount:0,
        percentage:0,
      },
      discount_amount:0,
      total_discount:0,
      total_amount:0,
      partial_amount:0,
      due_amount:0,
      advanced_deposit:{
       amount:0,
       paid_date:"",
       cashier_name:"",
       payment_method:"",
       remarks:""
      }
    },
    payment_details:{
    payment_method:"",
    payment_status:"",
    health_insurance_credit:{},
    remarks:"",
    },


  }

  clearData()
  {
    this.billingsData = [];
    this.ipdBillObject = {
      billing_date:moment().format("YYYY-MM-DD"),
      date_of_discharge:moment().format("YYYY-MM-DD"),
      time_of_discharge:moment().format("HH:mm"),
      total_admit_days:0,
      billing_items:[],
      billing_details:{
        subtotal:0,
        discount:{
          amount:0,
          percentage:0,
        },
        discount_amount:0,
        total_discount:0,
        total_amount:0,
        partial_amount:0,
        due_amount:0,
        advanced_deposit:{
         amount:0,
         paid_date:"",
         cashier_name:"",
         payment_method:"",
         remarks:""
        }
      },
      payment_details:{
      payment_method:"",
      payment_status:"",
      health_insurance_credit:{},
      remarks:"",
      },
  
  
    }
  }
  createPartialPayment(event:any,i:any)
  {

    if(event.target.checked)
    {
      this.partialBillingData.push(this.billing_items[i]);
      console.log(this.partialBillingData)
    }
  }
  billing_items:any = [];
  item:any =  {
    date:moment().format("YYYY-MM-DD HH:mm"),
    cat_id:"",
    cat_name:"",
    item_id:"",
    item_name:"",
    qty:0,
    price:0,
    subtotal:0,
    discount:0,
    item_discount:0,
    discount_amount:0,
    tax:0
  }
  
  billingCategoryList:any = [];
  billingMasterPriceList:any = [];
  count:Number = 0;
  insuranceCredit:boolean = false;
  dateDiff:any = 0;
  constructor(private ipdChartData:IpdchartService,private appointment:IpdAppointmentService,private toast:ToastrService, public route:ActivatedRoute,private modalService:NgbModal,private billing:BillingService,private localStorage:LocalStorageService)
  {

    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.current_app_id = params['ipd_app_id']
      })
  }
 
  async getImageFromCanvas(page:any)
  {
    const canvas = await html2canvas(page);
    console.log(canvas);
    return   canvas.toDataURL("image/png", 1.0);;
  }
  getDimensionPage(doc:any,image:any)
  {
    let bufferX = 5;
    const bufferY = 5;
    const imgProps = (<any>doc).getImageProperties(image);
    const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferY;
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    const type = "PNG";
    const compression = 'FAST';
    return {image,type, bufferX, bufferY, pdfWidth, pdfHeight, undefined, compression}
  }
  async printPage()
  {
 
    const discharge_summery = document.getElementById('bill') as HTMLElement;
   
      const doc = new jsPDF();
     var bill:any  = await this.getImageFromCanvas(discharge_summery);
      const param:any = this.getDimensionPage(doc,bill);
      doc.addImage(param.image,param.type, param.bufferX, param.bufferY, param.pdfWidth, param.pdfHeight, undefined,param.compression);
      //doc.addPage();
      var pdfUrl = URL.createObjectURL(doc.output('blob'));
      window.open(pdfUrl, "_blank")?.print();
  
  

   // doc.addPage();


 
  }

  clear()
  {
   
    this.ipdBillObject = {
      billing_date:moment().format("YYYY-MM-DD"),
      date_of_discharge:moment().format("YYYY-MM-DD"),
      time_of_discharge:moment().format("HH:mm"),
      total_admit_days:0,
      billing_items:[],
      billing_details:{
        subtotal:0,
        discount:{
          amount:0,
          percentage:0,
        },
        discount_amount:0,
        total_discount:0,
        total_amount:0,
        partial_amount:0,
        due_amount:0,
        advanced_deposit:{
         amount:0,
         paid_date:"",
         cashier_name:"",
         payment_method:"",
         remarks:""
        }
      },
      payment_details:{
      payment_method:"",
      payment_status:"",
      health_insurance_credit:{},
      remarks:"",
      },
  
  
    }
    this.billing_items =  [];
    this.calculateBillingDetails();
   this.modalService.dismissAll();
  }

  changeItem(event:any)
  {

    let value = this.billingMasterPriceList.find((item:any)=> item.id == event.target.value)
    let category = this.billingCategoryList.find((item:any)=> item.id == this.item.cat_id)
    this.item.price = value.price
    this.item.item_name = value.item
    this.item.cat_name = category.name
    this.item.qty = 1

    //console.log( this.item)

  }
  getCreditOrganization()
  {
    let param = {
      where:{
        hospital_id:this.localStorage.getHospitalId(),
      }
    }
    this.billing.getCreditOrganization(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.creditOrg = res;
        }
      },
      error:(e)=>{}
    })
  }
  getIPDbillsByIPDAppId()
  {
    this.loading = true;
    let param = {
      where:{
        ipd_app_id:this.current_app_id,
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId()
      }
    }
    this.billing.getIPDbillsByIPDAppId(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.billingsData = res;
        }
        this.loading = false;
      },
      error:(e)=>{
        this.loading = false;
      }
    })
  }
  calculateBillingDetails()
  {
    let discount = 0;
    let tax = 0;
    let price = 0;
    let subtotal = 0;
    let item_discount = 0;
    
    

    this.billing_items.forEach((element:any) => {

      discount = discount + element.discount_amount;
      tax = tax + element.tax;
      price = price + element.price;
      subtotal = subtotal + element.subtotal;
      item_discount = item_discount + element.item_discount

    });

    this.ipdBillObject.billing_details.subtotal = subtotal;
    this.ipdBillObject.billing_details.total_amount = subtotal - item_discount;
    this.ipdBillObject.billing_details.discount_amount = discount;
    let percentage = (item_discount) ? ((item_discount)/this.ipdBillObject.billing_details.subtotal * 100).toFixed(2) : 0
    this.ipdBillObject.billing_details.discount = {amount:item_discount,percentage:percentage};

    // this.totalDiscount = discount;
    // this.totalPrice = price;
    // this.totalTax = tax;
    // this.billing.amount = ( this.totalPrice + this.totalTax) - this.totalDiscount;
    // this.billing.paid_amount = this.billing.amount;
  
  }

  printBill(content:any,item:any)
  {
    
    
    let param = {
      hospital_id:item.hospital_id,
      center_id:item.center_id,
      id:item.id,
    }
    this.billing.getIpdBillingPrintData(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.modalService.open(content,  {fullscreen:false, size:'xl', scrollable: true,ariaLabelledBy: 'modal-basic-title' });
          console.log(res)
          this.printData = res;
        }
      },
      error:(err)=>{

      }
    })
  }
  generateInvoiceNo(content:any,item:any)
  {
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'yes') {  
        this.btnLoadingRecNo = true;
 
         let param = {
          hospital_id:item.hospital_id,
          center_id:item.center_id,
          id:item.id,
          patient_id:item.patient_id
         }
           this.billing.generateIPDBillNo(param).subscribe({
            next:(res)=>{
              if(res)
              {
                item.ipd_bill_no = res;
              
                this.btnLoadingRecNo = false;
              }
              else
              {
                this.btnLoadingRecNo = false;
     
              }
            
            },
            error:(e)=>{
              console.log(e)
            }
          })
      }  
    }, (reason) => {  

    });  
    
  }
  addItem()
  {
    this.item.subtotal = this.item.price * this.item.qty;
    let discountValue = (this.item.discount > 0) ? (this.item.subtotal) * (this.item.discount/100) : 0;
    this.item.discount_amount =  (this.item.discount > 0) ? (this.item.subtotal) - discountValue : 0;
    this.item.item_discount = discountValue;

    this.billing_items.push(this.item)
    this.calculateBillingDetails();
    this.item =  {
      date:moment().format("YYYY-MM-DD HH:mm"),
      cat_id:"",
      cat_name:"",
      item_id:"",
      item_name:"",
      qty:0,
      price:0,
      subtotal:0,
      discount:0,
      item_discount:0,
      discount_amount:0,
      tax:0
    }
  }
  removeItem(item:any)
  {
   this.billing_items = this.billing_items.filter((row:any)=>{ return item.item_id != row.item_id});
   this.calculateBillingDetails();
  }
  onChangePaymentMethod(event:any)
  {
    let value:string = event.target.value;
   this.insuranceCredit = (value.toLowerCase() == 'bill') ? true : false;
   if(event.target.value=='Bill')
   {
    this.getCreditOrganization();
    this.ipdBillObject.payment_details.payment_status = "paid"
   }
  }

  ngAfterViewInit()
  {
    
    this.getIPDbillsByIPDAppId();
  }
  SaveIPDInvoice()
  {
   
   let param =  {hospital_id:this.localStorage.getHospitalId(),
    center_id:this.localStorage.getCenterId(),
    patient_id:this.current_patient_id,
    ipd_app_id:this.current_app_id,
    ipd_number:this.ipdDetailObject?.ipd_number}
    this.ipdBillObject.billings_items = this.billing_items
    this.ipdBillObject.total_admit_days =this.dateDiff;
   this.ipdBillObject = {...this.ipdBillObject, ...param}
    //console.log( this.ipdBillObject)
    if(this.id)
    {
      this.btnLoading = true;
      this.billing.updateIPDBilling(this.ipdBillObject).subscribe({
        next:(res)=>{
          if(res)
          {
            this.modalService.dismissAll();
            this.toast.success("Data submitted successfully")
            this.btnLoading = false;
            this.getIPDbillsByIPDAppId();
            this.clearData();
          }
        },
        error:(e)=>{
          this.btnLoading = false;
          this.modalService.dismissAll();
        }
      })
    }
    else
    {
      this.btnLoading = true;
      this.billing.createIPDBilling(this.ipdBillObject).subscribe({
        next:(res)=>{
          this.toast.success("Data submitted successfully")
          this.btnLoading = false;
          this.modalService.dismissAll();
          this.getIPDbillsByIPDAppId();
          this.clearData();
     
        },
        error:(e)=>{

          this.btnLoading = false;
          this.modalService.dismissAll();
        }
      })
    }

  }
  onPaymentStatus(event:any)
  {
      this.partialPaidInput = (event.target.value=='partial') ? true : false
  }
  fnGetBillingModuleData()
  {
    let param = {
      "where": {
          "hospital_id": this.localStorage.getHospitalId(),
          "center_id": this.localStorage.getCenterId(),
          "id": this.current_app_id
      },
       "attributes_patient": [
          "pId",
          "prefix",
          "fname",
          "lname",
          "gender",
          "age",
          "mobile",
          "email"
      ],
      "attributes_ipd": [
          "ipd_number",
          "date_of_admission",
          "time_of_admission",
          "date_of_discharge",
          "time_of_discharge",
          "room_no",
          "room_type",
          "id",
          "doctor_incharge"
      ]
  }
    this.billing.getBillingModuleData(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.ipdDetailObject = res;
          this.onDateChageCalculation();
         
          
        }
      },
      error:(e)=>{

      }
    })
  }
  onDateChageCalculation()
  {
    this.dateDiff  = this.calculateDateDifferenceInDays(this.ipdDetailObject.date_of_admission,this.ipdBillObject.discharge_date)
  }


  loadBillingCategory()
  {
    this.loading_cat = true;
    this.billing.getAllPriceCategory().subscribe({
      next:(res)=>{
        if(res){
          this.billingCategoryList = res;
          this.loading_cat = false;
        }
      
      },
      error:(e)=>{
        this.loading_cat = false;

      }
    })
  }
  fnOnCreditOrgChange(event:any)
  {
    let value = event.target.value;
   if(value)
   {
    let row = this.creditOrg.find((item:any)=>  item.id == value);
    this.ipdBillObject.payment_details.health_insurance_credit = {id:row.id,name:row.name}
   }
  }
  fnLoadIntraOpItems() {

      // console.log(this.app_data)
    
     let param = {
      where:{
     hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
      patient_id:this.current_patient_id,
      ipd_app_id:this.current_app_id
      },
      attributes:['id','ipd_app_id','hospital_id','patient_id','center_id','procedures','intra_op']
    }
    this.ipdChartData.getAllIpdChartData(param).subscribe({
      next:(res)=>{
       if(res)
       {
         let existingObject  = res[0] || []
         if(existingObject && existingObject.intra_op.surgical_items){
          console.log(existingObject.intra_op.surgical_items)
          existingObject.intra_op.surgical_items.forEach((element:any) => {
          this.item.item_name = element.item_name;
          this.item.cat_name = 'Intraoperative';
          this.item.date_added = element.date_added;
          this.item.qty = element.qty;
           this.item.subtotal = element.item_price *element.qty;
          // let discountValue = (this.item.discount > 0) ? (this.item.subtotal) * (this.item.discount/100) : 0;
          // this.item.discount_amount =  (this.item.discount > 0) ? (this.item.subtotal) - discountValue : 0;
          // this.item.item_discount = discountValue;
      
          this.billing_items.push(this.item)
          this.calculateBillingDetails();
          this.item =  {
            date:moment().format("YYYY-MM-DD HH:mm"),
            cat_id:"",
            cat_name:"",
            item_id:"",
            item_name:"",
            qty:0,
            price:0,
            subtotal:0,
            discount:0,
            item_discount:0,
            discount_amount:0,
            tax:0
          }
          });
          
         }
        
       }
    
      },
      error:(e)=>{
    
      }
    })
    
  }
  loadPriceMasterItems(event:any)
  {
    if(event.target.value=='intraoperative') {
      this.fnLoadIntraOpItems();
    }
    else {
      this.loading_sub_cat = true;
      let param = {
         hospital_id:this.localStorage.getHospitalId(),
         center_id:this.localStorage.getCenterId(),
         cat_id:event.target.value
      }
      this.billing.getAllPriceMaster(param).subscribe({
        next:(res)=>{
          if(res){
            this.billingMasterPriceList = res;
            console.log( this.billingMasterPriceList )
            this.loading_sub_cat = false;
          }
        
        },
        error:(e)=>{
          this.loading_sub_cat = false;
        }
      })
    }
   
  }
  calculateDateDifferenceInDays(startDate:any, endDate:any) {
    // Parse the input strings to Moment objects
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
  
    // Calculate the difference in days
    const daysDifference = endMoment.diff(startMoment, 'days');
  
    return daysDifference;
  }
  editBill(content:any,item:any)
  {
    this.fnGetBillingModuleData();
    this.ipdBillObject = item;
    this.billing_items = item.billings_items;
    this.id = item.id;
    if(this.ipdBillObject.payment_details.payment_method=='Bill')
    {
      this.getCreditOrganization();
    }
    this.modalService.open(content,  {   fullscreen:true,size:'xl', scrollable: true,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'Cross click') {  
      
      }  

    }, (reason) => {  

    });  
  }
  OpenNewBill(content:any)
  {
    this.loadBillingCategory();
    this.fnGetBillingModuleData();
    this.modalService.open(content,  {   fullscreen:true,size:'xl', scrollable: true,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'Cross click') {  
       
       
      }  

    }, (reason) => {  

    });  
  }
  dischargePatient(model:any,item:any)
  {
    this.modalService.open(model,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'yes') {  
        this.btnLoadingDischarge = true;
 
         let param = {
          hospital_id:item.hospital_id,
          center_id:item.center_id,
          id:this.current_app_id,
          patient_id:this.current_patient_id,
          status:"discharge",
         }
           this.appointment.updateIpdAppointment(param).subscribe({
            next:(res)=>{
              if(res)
              {
                this.toast.success("Patient Discharged successfully ")
                this.dischargeFunction.emit();
                this.btnLoadingDischarge = false;

              }
              else
              {
                this.btnLoadingDischarge = false;
     
              }
            
            },
            error:(e)=>{
              console.log(e)
            }
          })
      }  
    }, (reason) => {  

    });  
  }
}
